import { breakpoints, Button, styled, ty } from '@gcc/autobahn-components';

export default {};

export const InstallationSection = styled.section`
  ${ty`pb3 mb4 bb b--gray`}
`;

export const Installation = styled.div`
  ${ty`pa3 mb2 bg-mid-gray br2 flex relative`}

  ${breakpoints.ns} {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  & i {
    ${ty`pt1`}
  }
  & h3 {
    ${ty`black`}
  }
  & p {
    ${ty`mb3 black`}
  }
  & > div {
    ${ty`pl3`}
  }
  & h3 {
    ${ty`b`}
    font-size: 18px;
    span {
      font-weight: normal;
      font-style: italic;
    }
  }
  & p {
    font-size: var(--font-size-7)
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-extra-small);
  gap: var(--spacing-extra-small);
`;

export const CtaButton = styled(Button)`
  ${ty`bg-white`}
  ${breakpoints.s} {
    ${ty`ph3`}
  }
`;

export const ToolTipContainer = styled.div`
  font-size: var(--font-size-6);
  
  /* harmony theme makes links black, apparently */
  & > span > button {
    color: var(--blue);
  }
`;

export const ToolTipLink = styled.div`
  ${ty`relative`}
`;

export const ToolTipBox = styled.div`
  ${ty`ph3 pb3 pt1`}
  width: 300px;
`;

export const ToolTipHeader = styled.div`
  ${ty`fw7`}
  font-size: 1.125rem;
`;

export const ToolTipSubHeader = styled.div`
  ${ty`f6 fw7 mt3`}
  ::before {
    position: absolute;
    content: '•';
    margin-left: -0.75rem;
  }
`;

export const ToolTipText = styled.div`
  ${ty`f6 fw4`}
`;

export const ToolTipFooter = styled.div`
  ${ty`mt4 pt3 bt b--gray f6 fw4`}
`;

export const ToolTipInfoIcon = styled.span`
  ${ty`f4 absolute`}
  margin-left: 0.25rem;
  margin-top: -0.2rem;
`;
