import axios from 'axios';
import { CustomMeasureAndInstallExpConfiguration } from '../utility/customMeasureAndInstallExpConfiguration';

const config = {
  withCredentials: true,
  crossDomain: true,
};

export default (zipCode) => {
  const baseMandiUrl = CustomMeasureAndInstallExpConfiguration.MANDI_BASE_URL;

  return axios
    // eslint-disable-next-line max-len
    .get(`${baseMandiUrl}/api/service-providers/check-pricing/zip/${zipCode}`, {
      params: {
        numberOfWindows: 1,
      },
      ...config,
    })
    .then((result) => result)
    // eslint-disable-next-line no-console
    .catch((e) => console.error(e));
};
