import { Icon, Tooltip, useBreakpoint } from '@gcc/autobahn-components';
import { string } from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  Installation,
  CtaButton,
  HeaderRow,
  InstallationSection,
  ToolTipBox,
  ToolTipContainer,
  ToolTipFooter,
  ToolTipHeader,
  ToolTipInfoIcon,
  ToolTipLink,
  ToolTipSubHeader,
  ToolTipText,
} from './product-installation-cta.styles';
import ProductInstallationCTAModal from './product-installation-cta-modal/product-installation-cta-modal.index';
import getBasePricing from '../../../../api/getBasePricing';
import { getCookie } from '../../../../utility/windowHelper';

const ProductInstallationCta = ({ mandIPhoneNumber }) => {
  const { isSmall, isExtraLarge } = useBreakpoint();
  const [isBasePriceDollar, setIsBasePriceDollar] = useState(false);
  const installSectionText = {
    header: null,
    body: null
  };
  const zipcode = getCookie('DELIVERY_ZIP');
  console.log(useBreakpoint());

  // Display a different message if base price is a dollar
  if (isBasePriceDollar) {
    installSectionText.header = <>Professional in-home Measure now only $1! <span>($49 value)</span></>;
    installSectionText.body = 'Get expert results with Professional Measure and Installation.';
  } else {
    installSectionText.header = 'Want Professional Installation?';
    installSectionText.body = 'Have one of our licensed pros install your window treatments.';
  }

  const mniToolTipLink = (
    <ToolTipLink>
      What to Expect
      <ToolTipInfoIcon>
        <Icon name="info" size="original" />
      </ToolTipInfoIcon>
    </ToolTipLink>
  );
  const mniToolTipContent = (
    <ToolTipBox>
      <ToolTipHeader>How Professional Installation Works</ToolTipHeader>
      <ToolTipSubHeader>Step 1: Check Availability</ToolTipSubHeader>
      <ToolTipText>Confirm our services in your area</ToolTipText>
      <ToolTipSubHeader>Step 2: Measure</ToolTipSubHeader>
      <ToolTipText>We measure your windows for you</ToolTipText>
      <ToolTipSubHeader>Step 3: Build</ToolTipSubHeader>
      <ToolTipText>Your window treatments are custom made</ToolTipText>
      <ToolTipSubHeader>Step 3: Install</ToolTipSubHeader>
      <ToolTipText>We install your window treatments</ToolTipText>
      <ToolTipFooter>
        Have questions? Call us at
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <b> {mandIPhoneNumber || '877-865-9871'} </b>
        and we&apos;ll help you out!
      </ToolTipFooter>
    </ToolTipBox>
  );

  // Get the base price and check if it's a dollar or not
  useEffect(() => {
    getBasePricing(zipcode)
      .then((result) => {
        const resultData = result?.data?.result;
        const baseMeasurePrice = resultData?.baseMeasurePrice || 0;
        setIsBasePriceDollar(baseMeasurePrice === 1);
      });
  }, [setIsBasePriceDollar, getBasePricing, zipcode]);

  return (
    <InstallationSection>
      <Installation id="zone-a-installation">
        <Icon id="drill-icon" color="var(--orange)" name="drill" size="original" />
        <div>
          <HeaderRow>
            <h3> {installSectionText.header}</h3>
            {isExtraLarge && !isBasePriceDollar && (
              <ToolTipContainer>
                <Tooltip linkText={mniToolTipLink} hideOnClick interactive showClose trigger="click" placement="bottom">
                  {mniToolTipContent}
                </Tooltip>
              </ToolTipContainer>
            )}
          </HeaderRow>
          <p>
            {installSectionText.body}
            {!isSmall && (!isExtraLarge || (isBasePriceDollar)) && (
              <ToolTipContainer>
                <Tooltip linkText={mniToolTipLink} hideOnClick interactive showClose trigger="click" placement="bottom">
                  {mniToolTipContent}
                </Tooltip>
              </ToolTipContainer>
            )}
          </p>
          <CtaButton
            label="Check Availability"
            onClick={() => {
              window.location = '/custom-blinds/schedule';
            }}
            outline
          />
          {isSmall && (
            <ToolTipContainer>
              <ProductInstallationCTAModal title={mniToolTipLink}>{mniToolTipContent}</ProductInstallationCTAModal>
            </ToolTipContainer>
          )}
        </div>
      </Installation>
    </InstallationSection>
  );
};

ProductInstallationCta.propTypes = {
  mandIPhoneNumber: string,
};

ProductInstallationCta.defaultProps = {
  mandIPhoneNumber: undefined,
};

export default ProductInstallationCta;
